module.exports = {
  siteTitle: 'Mazhar Ali Baig | Software Engineer',
  siteDescription:
    'Hello! I\'m Mazhar Ali Baig, a software engineer with a love for squeaky clean code.',
  siteKeywords:
    'Mazhar Ali, Mazhar, Baig, mazharalibaig, react developer, front-end engineer, web developer, javascript, IIIT Jabalpur',
  siteUrl: 'https://mazharalibaig-portfolio.vercel.app',
  siteLanguage: '',
  googleAnalyticsID: '',
  googleVerification: '',
  name: 'Mazhar Ali Baig',
  location: 'Hyderabad, Telangana, India',
  email: '2018145@iiitdmj.ac.in',
  github: 'https://github.com/mazharalibaig',
  twitterHandle: '@MazharAliBaig3',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/mazharalibaig',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/mazhar-ali-baig/',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/mazhar_ali_baig/',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/MazharAliBaig3',
    },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
